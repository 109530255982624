import * as React from "react"
import '../styles/global.scss';
import Layout from "../components/layout";

export default class HelpPage extends React.Component {
    public render() {
        return (
            <Layout isHomePage={false}>
                <div className={'row mt-4'}>
                    <div className={'col-12'}>
                        <h2>Pomoc</h2>
                        <p>W celu uzyskania pomocy przy korzystaniu z aplikacji mobilnej zachęcamy do skorzystania z
                            chatu dostępnego w prawym dolnym rogu strony.</p>
                        <p>Jesteśmy również do dyspozycji pod adresem mailowym <a
                            href={"mailto:pomoc@loggy.pl"}>pomoc@loggy.pl</a></p>

                        <h3>Instrukcja dodawania delegacji</h3>
                        <div className={"row"}>
                            <div className="col-sm-12 col-md-12 col-xl-8">
                                <iframe width="100%" height="315" src="https://www.youtube.com/embed/krnGuOX5KYA"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>

                </div>
            </Layout>

        )
    }
}
